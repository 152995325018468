/** @jsx jsx */

import { FC, useRef, useState, useEffect, createContext } from 'react'
import { Link } from 'gatsby'
import { Box, Flex, Text, jsx, Link as BaseLink, Button, useColorMode } from 'theme-ui'

import { motion } from 'framer-motion'
// import { useResponsiveValue, useBreakpointIndex } from '@theme-ui/match-media'
import { MenuButton, Close, Container } from '@theme-ui/components'

import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { BrandLogo } from './icon'

const linksVariants = {
  open: { display: 'flex' },
  closed: { display: 'block' },
}
const transition = {
  x: { type: 'spring', stiffness: 100 },
  opacity: { ease: 'easeIn' },
}

function BxSunIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" height="1em" width="1em" {...props}>
      <path d="M6.993 12c0 2.761 2.246 5.007 5.007 5.007s5.007-2.246 5.007-5.007S14.761 6.993 12 6.993 6.993 9.239 6.993 12zM12 8.993c1.658 0 3.007 1.349 3.007 3.007S13.658 15.007 12 15.007 8.993 13.658 8.993 12 10.342 8.993 12 8.993zM10.998 19h2v3h-2zm0-17h2v3h-2zm-9 9h3v2h-3zm17 0h3v2h-3zM4.219 18.363l2.12-2.122 1.415 1.414-2.12 2.122zM16.24 6.344l2.122-2.122 1.414 1.414-2.122 2.122zM6.342 7.759L4.22 5.637l1.415-1.414 2.12 2.122zM19.776 18.364l-1.414 1.414-2.122-2.122 1.414-1.414z" />
    </svg>
  )
}

interface Links {}

interface LinkItem {
  to: string
  children: any
  href?: string
}

const links: LinkItem[] = [
  { to: `/team`, children: `Team` },
  { to: `/services`, children: `Services` },
  { to: `/case-studies`, children: `Works` },
  { to: `/contact`, children: `Contact` },
]

export interface NavigationProps {
  alt?: any
  invert?: boolean
}

const Navigation: FC<NavigationProps> = ({ alt, invert }) => {
  const [colorMode, setColorMode] = useColorMode()
  const [isOpen, setOpen] = useState(false)
  const containerRef = useRef(null)
  // const breakPointIndex = useBreakpointIndex()

  const [hideOnScroll, setHideOnScroll] = useState<boolean>(true)
  const [hideOnScroll2, setHideOnScroll2] = useState<boolean>(false)
  const [prevPosV, setPrevPosV] = useState<any>()
  const [currPosV, setCurrPosV] = useState<any>()

  const [activeVariant, setActiveVariant] = useState<string>('basic')
  const [menuState, setMenuState] = useState<string>('nothing')

  const [plateColor, setPlateColor] = useState<string>('primarytext')

  const bannerHeight = -350
  const main = 'primary'

  useEffect(() => {
    // console.log('x', prevPosV)
  }, [prevPosV])

  // useEffect([])

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y
      setPrevPosV(currPos.y)

      if (currPos.y > bannerHeight) {
        setHideOnScroll2(true)
        // invert && console.log('invertable')
        // !invert && console.log('not invertable')

        invert && setPlateColor('primarytextinvert')
        !invert && setPlateColor('primarytext')
      }

      if (currPos.y < bannerHeight) {
        setMenuState('home')
        setHideOnScroll2(false)
      }

      if (isShow !== hideOnScroll) setHideOnScroll(isShow)
    },
    [hideOnScroll],
  )

  return (
    <Box as="header">
      <motion.aside
        ref={containerRef}
        initial={true}
        animate={isOpen ? 'open' : 'closed'}
        transition={transition}
        // variants={breakPointIndex !== 0 ? sideVariants : topVariants}
        sx={{
          flexGrow: 1,
          // position: 'fixed',
          position: hideOnScroll ? 'fixed' : 'absolute',
          left: 0,
          right: 0,
          // bg: '#11111182',
          zIndex: 1000,
          bg: 'base',
          top: [0, 'auto', 'auto'],
          bottom: ['auto', 'auto', 'auto'],
          borderBottom: 'solid 1px',
          borderColor: 'grays.3',
          opacity: hideOnScroll || hideOnScroll2 ? 1 : 0,
          flexBasis: 'sidebar',
          minWidth: ['100vw', 'auto', 'auto', 'auto'],
        }}
      >
        <Box>
          <Flex
            as="nav"
            role="navigation"
            sx={{
              position: 'relative',
              bg: hideOnScroll2 ? 'transparent' : 'transparent',
              // minHeight: `7rem`,
              py: 5,
              mb: 0,
              px: 5,
            }}
          >
            <Flex
              sx={{
                // bg: 'blue',
                justifyContent: `center`,
                alignItems: `center`,
                display: ['flex', 'inherit', 'inherit'],
              }}
            >
              <Link
                sx={{
                  width: `auto`,
                  // height: `3rem`,
                  display: `flex`,
                  alignItems: `center`,
                  justifyContent: `center`,
                  // bg: 'red'
                }}
                to="/"
              >
                {/* ml={[3, 3, 4]} */}
                <Box sx={{ minWidth: '22ch' }}>
                  <Box
                    sx={{
                      color: 'blue.3',
                      svg: { opacity: 1, path: { fill: 'grays.8', '&:hover': { fill: 'red.9' } } },
                    }}
                  >
                    <BrandLogo
                      sx={{
                        height: '1ch',
                      }}
                    />
                  </Box>
                </Box>
              </Link>
            </Flex>
            <motion.div
              variants={linksVariants}
              sx={{
                display: 'flex',
                flex: 1,
                flexGrow: 1,
              }}
            >
              <Box>
                <Flex
                  as="ul"
                  variant="box.navList"
                  sx={{
                    display: [isOpen ? 'flex' : 'none', 'none', 'flex'],
                    position: [isOpen ? 'absolute' : 'relative', 'relative', 'relative'],
                    bg: 'grays.0'
                  }}
                >
                  {links.map((_props, index) => (
                    <Flex as="li" sx={{ pt: 0, alignItems: `center`, mr: 3 }} key={_props?.to}>
                      {_props?.to && (
                        <Link
                          as={BaseLink}
                          sx={{ color: 'grays.8', fontWeight: 600 }}
                          variant="box.navList"
                          {...{ to: _props.to }}
                        >
                          {_props.children}
                        </Link>
                      )}
                    </Flex>
                  ))}
                  <Box>
                    {/* <Button sx={{ bg: 'grays.8', color: 'grays.0', fontWeight: 900 }}>Talk to us</Button> */}
                  </Box>
                  {/* <Box sx={{ pt: 5, ml: 4, svg: { fill: 'basealt'}}}>
                    <BxSunIcon sx={{ width: '2rem', height: '2rem'}} onClick={() => setColorMode(colorMode === `default` ? `dark` : `default`)} />
                  </Box> */}
                </Flex>
              </Box>
            </motion.div>
            <Flex
              as="div"
              sx={{ bg: 'transparent', zIndex: 10000, right: 0, position: `absolute`,  mr: 0 }}
              key="1"
            >
              <Box
                sx={{
                  color: 'primarytextinvert',
                  px: [0, 3],
                  height: 48,
                  fontSize: 1,
                  display: ['flex', 'flex', 'none'],
                  //   display: [`Home`, `Speaking`].includes(children) ? [`none`, `flex`] : `flex`,
                  alignItems: `center`,
                  fontWeight: 'bold',
                }}
              >
                {!isOpen ? (
                  <MenuButton
                    onClick={() => setOpen(!isOpen)}
                    sx={{
                      float: 'right',
                      color: 'primarytext',
                    }}
                    aria-label="Toggle Menu"
                  />
                ) : (
                  <Close
                    onClick={() => setOpen(!isOpen)}
                    sx={{
                      float: 'right',
                      color: 'primarytext',
                    }}
                    aria-label="Toggle Menu"
                  />
                )}
              </Box>
            </Flex>
          </Flex>
        </Box>
      </motion.aside>
    </Box>
  )
}

export default Navigation
